export default {
    blue: "rgba(70,136,255,1)",
    white: "rgba(255, 255, 255, 1)",
    black: "rgba(0, 0, 0, 1)",
    space_style(direction, gap) {
        var fdirection = "row"
        var rowgap = 0;
        var colgap = 0;
        if (direction === 'v') {
            fdirection = "column"
            rowgap = gap
            colgap = gap
        }
        else {
            fdirection = "row"
            rowgap = gap
            colgap = gap
        }
        return {
            display: "inline-flex",
            flexDirection: fdirection,
            columnGap: colgap,
            rowGap: rowgap
        }
    },
}