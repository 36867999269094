import { Layout, Space } from "antd";
import bg from "./assets/bg2x.png"
import logo from "./assets/Logo.png"

import MainContent from "./MainContent";
import common from "./common";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
function App() {
	useEffect(() => {
		document.title = "图小小相册"
	}, [])
	return (
		<div style={{ margin: -10, width: "160rem" }}>
			<Layout className="layout" hasSider={false} style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "160rem", height: "90rem" }}>
				<Header style={{ display: "inline-flex", padding: "0rem", width: "160rem", height: "6.666666666666667rem", background: "rgba(255,255,255,.9)", boxShadow: "0.16666666666666666rem 0.4166666666666667rem 0.8333333333333334rem 0rem rgba(35,39,44,0.06)" }} >
					<div direction="horizontal" style={{ ...common.styleutils.space_style("h", "1.75rem"), transform: "translate(30rem, 0rem)", lineHeight: "6.666666666666667rem" }}>
						<img src={logo} style={{ width: "3.6666666666666665rem", height: "3.6666666666666665rem", transform: "translate(0rem, 1.5rem)", }} />
						<div style={{ ...common.styleutils.space_style("h", "73.66666666666667rem") }}>
							<div style={{ fontFamily: "MicrosoftYaHei", fontWeight: "bold", fontSize: "2rem" }}>
								图小小相册
							</div>
							<div style={{ fontFamily: "MicrosoftYaHei", fontSize: "1.5rem", fontWeight:400 }}>
                                小小相册大大口袋
							</div>
						</div>

					</div>
				</Header>
				<Content>
					<MainContent />
				</Content>

			</Layout >
			<div style={{ width: "160rem", height: "6.666666666666667rem", background: "rgba(30, 30, 30, 1)", position: "relative" }}>
				<div style={{ position: "absolute", fontSize: "1.3333333333333333rem", fontFamily: "SourceHanSansCN", color: "rgba(153, 153, 153, 1)", lineHeight: "6.666666666666667rem", transform: "translate(29.916666666666668rem, 0)" }}>
					版权所有: 上海双深信息技术有限公司   备案号: 沪ICP备2021004298号-2A
				</div>
			</div>
		</div >
	);
}

export default App;
