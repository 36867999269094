import axios from "axios";
export default {
    async getBack() {
        const url = "https://" + window.__globalEnvData__.REACT_APP_IP
        return await axios({
            method: "GET",
            url: url
        }).then((res) => {
            if (parseInt(res.data.errorCode) !== 200)
                return [false, res.data.message]
            return [true, res.data.data]
        }).catch((err) => {
            return [false, err.message]
        })
    }
}