import title from "./assets/title.png"
import maincontent from "./assets/maincontent.png"
import android_b from "./assets/android_b.png"
import android_w from "./assets/android_w.png"
import ios_b from "./assets/ios_b.png"
import ios_w from "./assets/ios_w.png"
import qr from "./assets/qr.png"
import { Button, Slider, Space } from "antd"
import { useEffect, useRef, useState } from "react"
import ColumnGroup from "antd/es/table/ColumnGroup"
import common from "./common"
import Fade from "react-reveal/Fade"
import { useNavigate } from "react-router-dom";
import api from "./api"
function MainContent() {
    const navigate = useNavigate()
    // 后端信息
    const [androidVersion, set_androidVersion] = useState("v1.0")
    const [androidUrl, set_androidUrl] = useState("")
    const [androidIcon, set_androidIcon] = useState("")
    const [iOSVersion, set_iOSVersion] = useState("v1.0")
    const [iOSUrl, set_iOSUrl] = useState("")
    const [iOSIcon, set_iOSIcon] = useState("")
    const getBack = async () => {
        var res = await api.getBack()
        if (!res[0]) {
            setTimeout(getBack, 500)
        }
        res = res[1]
        set_androidVersion(res.androidVersion)
        set_androidUrl(res.androidUrl)
        set_iOSVersion(res.iOSVersion)
        set_iOSUrl(res.iOSUrl)
        var iIcon = new Image()
        iIcon.onload = () => {
            set_iOSIcon(res.iOSIcon)
        }
        iIcon.src = res.iOSIcon

        var aIcon = new Image()
        aIcon.onload = () => {
            set_androidIcon(res.androidIcon)
        }
        aIcon.src = res.androidIcon
    }
    useEffect(() => {
        getBack()
    }, [])
    const [mouse_in_button, set_mouse_in_button] = useState(0)
    const buttonShadow = "0rem 0rem 2.25rem 0.25rem rgba(15,71,171,0.2)"
    const onMouseEnter = (v) => {
        if (v.currentTarget.classList.contains('IPhoneButton'))
            set_mouse_in_button(1)
        else if (v.currentTarget.classList.contains('AndoridButton'))
            set_mouse_in_button(2)
        else
            return
    }
    const onClick = (v) => {
        if (v.currentTarget.classList.contains('IPhoneButton'))
            window.open(iOSUrl)
        else if (v.currentTarget.classList.contains('AndoridButton'))
            window.open(androidUrl)
        else
            return
    }
    const onMouseLeave = (v) => {
        set_mouse_in_button(0)
    }
    const button_style = {
        width: "20rem",
        height: "5.833333333333333rem",
        borderRadius: "2.8333333333333335rem",
        border: "none"
    }
    const getButtonStyle = (device) => {
        if (device === "iphone")
            return {
                ...button_style,
                backgroundColor: `${mouse_in_button === 1 ? common.styleutils.blue : common.styleutils.white}`,
                color: `${mouse_in_button === 1 ? common.styleutils.white : common.styleutils.black}`,
                boxShadow: `${mouse_in_button === 1 ? buttonShadow : "none"}`,
            }
        else
            return {
                ...button_style,
                backgroundColor: `${mouse_in_button === 2 ? common.styleutils.blue : common.styleutils.white}`,
                color: `${mouse_in_button === 2 ? common.styleutils.white : common.styleutils.black}`,
                boxShadow: `${mouse_in_button === 2 ? buttonShadow : "none"}`,
            }
        // console.log(v)

    }
    const button_text_img = {
        width: "3.6666666666666665rem",
        height: "3.6666666666666665rem",

    }
    const qr_style = {
        // marginBottom: "18.333333333333332rem",
        width: "13.333333333333334rem",
        backgroundColor: "rgba(70, 136, 255, 0.2)",
        padding: "0.8333333333333334rem",
        borderRadius: "0rem 0rem 1.25rem 1.25rem",
        textAlighn: "center"
    }
    return (
        <div style={{ ...common.styleutils.space_style("v", "3rem"), paddingLeft: "30rem", paddingTop: "14.833333333333334rem" }}>
            <img src={title} style={{ width: "26.166666666666668rem" }} />
            <div style={{ ...common.styleutils.space_style("v", "2.5rem") }}>
                <div style={{
                    width: "49.416666666666664rem",
                    height: "27.5rem",
                    fontSize: "1.5rem",
                    fontFamily: "Microsoft YaHei",
                    fontWeight: 400,
                    color: "#000000",
                    lineHeight:"2.5rem"
                }}>
                    图小小相册是高性价比的本地和云端照片视频备份APP，具备六大核心功能<br/>
                    1.图像相册内嵌AI智能空间优化算法，可安全备份您照片视频; <br/>
                    2.支持Livephotos、大视频随心上传;<br/>
                    3.批量上传下载不限速; <br/>
                    4.支持本地+云端双深备份，安全又灵活; <br/>
                    5.内嵌领先的语义搜索算法，只需输入场景描述或关键词（人物、蓝天等）或搜索相似图片，便可锁定您想要的照片;<br/>
                    6.智能整理功能，自动根据照片拍摄的时间、地点及标签分类；您也可以根据自己的喜好，给相册自由命名，轻松管理相册。<br/>
                    还有更多实用好玩的功能，欢迎下载体验~~~
                </div>
                {/* <img src={maincontent} style={{ width: "48.333333333333336rem" }} /> */}
                <div style={common.styleutils.space_style("h", "2.5rem")}>
                    <div style={{ ...common.styleutils.space_style("v", "0rem"), textAlign: "center", alignItems: "center" }}>
                        {/* ---------------------------------------展示iphone按钮--------------------------------------- */}
                        <button className="IPhoneButton"
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            style={getButtonStyle("iphone")}
                        >
                            <Space>
                                <img src={mouse_in_button === 1 ? ios_w : ios_b} style={button_text_img} />
                                <div style={{ fontFamily: "MicrosoftYaHei", textAlign: "left" }}>
                                    <div style={{ fontSize: "1.5rem" }}>
                                        iPhone版
                                    </div>
                                    <div style={{ fontSize: "1.1666666666666667rem" }}>
                                        {`最新版本: ${iOSVersion}`}
                                    </div>
                                </div>
                            </Space>
                        </button>
                        <Fade left distance="0rem">
                            <img src={iOSIcon} style={{ ...qr_style, display: mouse_in_button === 1 ? undefined : "none" }} />
                        </Fade>
                    </div>
                    {/* ---------------------------------------展示android按钮--------------------------------------- */}
                    <div style={{ ...common.styleutils.space_style("v", "0rem"), textAlign: "center", alignItems: "center" }}>
                        <button className="AndoridButton"
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            style={getButtonStyle("android")}
                        >
                            <Space>
                                <img src={mouse_in_button === 2 ? android_w : android_b} style={button_text_img} />
                                <div style={{ fontFamily: "MicrosoftYaHei", textAlign: "left" }}>
                                    <div style={{ fontSize: "1.5rem" }}>
                                        Android版
                                    </div>
                                    <div style={{ fontSize: "1.1666666666666667rem" }}>
                                        {`最新版本: ${androidVersion}`}
                                    </div>
                                </div>
                            </Space>
                        </button>
                        <Fade left distance="0rem">
                            <img src={androidIcon} style={{ ...qr_style, display: mouse_in_button === 2 ? undefined : "none" }} />
                        </Fade>
                    </div>
                </div>
            </div>
            {/* <img src={qr} style={{ marginBottom: "18.333333333333332rem", marginLeft: "2.5rem", backgroundColor: "rgba(70, 136, 255, 0.2)", padding: "0.8333333333333334rem", borderRadius: "1.25rem" }} /> */}
        </div >
    )
}
export default MainContent;