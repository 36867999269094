export default {
    getPxValue(inV) {
        var arr = inV.split('px');
        var num1 = arr[0] - 0;
        return num1
    },
    getFontSize() {
        var pageWidth = window.innerWidth;
        if (typeof pageWidth != "number") {
            if (document.compatMode == "number") {
                pageWidth = document.documentElement.clientWidth;
            } else {
                pageWidth = document.body.clientWidth;
            }
        }
        let scale = parseFloat(pageWidth) / 1920.0;
        let fontSize = scale * 12.0;
        if (fontSize < 4.5) {
            fontSize = 4.5;
        }
        // return fontSize + "px"
        return parseFloat(fontSize) + "px"
    },
    getRem(width) {
        let wV = this.getPxValue(width)
        // let fV = this.getPxValue(this.getFontSize())
        let rem = parseFloat(wV) / 12 + 'rem';
        return rem
    },
    // getRem(content, map, meta) {
    //     // let reg = /(\d+(\\.\d+)?)px/g
    //     let reg = /(?<num1>\d+)\.?(?<num2>\d+)?px/g  // 匹配所有px 相关的字符
    //     let content1 = content.replace(reg, function (...data) { // px 转换为带小数的rem
    //         var arr = data[data.length - 1]
    //         var num1 = 0, num2 = 0;
    //         if (arr.num1) num1 = parseFloat(arr.num1)
    //         if (arr.num2) num2 = parseFloat(arr.num2)
    //         return parseFloat(num1 + '.' + num2) / 16 + 'rem' // 这里以1.3333333333333333rem 为pc端转换基数 适配1920分辨率
    //     })
    //     console.log(content1)
    //     return content1
    // },
}