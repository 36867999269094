import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import common from './common';
const renderRoot = () => {
    return (
        <div>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </div>
    )
}
// let clientHeight = document.documentElement.clientHeight;
// let clientWidth = document.documentElement.clientWidth;
// document.body.style.overflow = 'hidden'
const root = ReactDOM.createRoot(document.getElementById('root'))
document.documentElement.style.fontSize = common.rem.getFontSize();
document.getElementById('root').style.setProperty('font-size', common.rem.getFontSize())
document.title = "图小小相册"
// document.getElementById('root').style.setProperty('width', clientWidth+"px")
root.render(renderRoot())